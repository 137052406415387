import * as React from "react"
import LoginStyles from "./login.css"
import theme from "../gatsby-plugin-theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Backdrop from '../images/section2.png'

import SEO from "../components/seo"
const Login =()=> {

    function login_submit_function(){
        var xhr = new XMLHttpRequest();
                      xhr.open("POST", '/api/login/', true);
                     var email = document.getElementById("enter_email_ele").value
                     var pass = document.getElementById("enter_pass_ele").value
                    
                      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                      xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"))
      
                    
                     
                     var  userObjXML = 'email='+email+'&pass='+encodeURIComponent(pass)+'&l='
                      xhr.send(userObjXML)
                    
                      xhr.onreadystatechange = function() { // Call a function when the state changes.
                      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                     
                      var ServerResponse = JSON.parse(xhr.responseText)
      
                      if (ServerResponse.err){
                    
                        var message = ServerResponse.err
                       window.alert(message)
      
                    }else {
                    var key = ServerResponse.token
                    localStorage.setItem("token", key);
                     document.getElementById('modal-toggle').checked = false
                     window.location = '/'
                
                
                    
                 
                    
      }
      }
      
      
      }
          
      }
      
      function frgt_pass_function(){
        var y = (document.getElementById('frgt_pass_email').value)
        if (y !== ""){
        var xhr = new XMLHttpRequest();
                      xhr.open("POST", '/forgot_pass/', true);
                      
                      //Send the proper header information along with the request
                      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                      xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"))
                   
      
                     var  keyObjXML = 'fg_email='+y//+''//&pass='+pass
                      xhr.send(keyObjXML)
                   // console.log(x)
                      xhr.onreadystatechange = function() { // Call a function when the state changes.
                      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                     
                      var ServerResponse = JSON.parse(xhr.responseText)
                    
                      console.log(ServerResponse)
                      if (ServerResponse.err){
      
                        alert(ServerResponse.err)
                      }
                      document.getElementById('modal-toggle').checked = false
                     window.location = '/'
      
      }}}}
      
      function signup_submit_function(){
        var email = document.getElementById("signin_email_ele").value
        var pass1 = document.getElementById("signin_pass1_ele").value
        var pass2 = document.getElementById("signin_pass2_ele").value
        if((pass1 === pass2)&&(email !== "")&&(pass1.length >= 4)&&(email.includes("@"))&&(email.endsWith("@")=== false)) {
        var xhr = new XMLHttpRequest();
                      xhr.open("POST", '/api/signup/', true);
                    
      
      
                      //Send the proper header information along with the request
                      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                      xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"))
      
                      
                     var  userObjXML = 'email='+email+'&pass='+encodeURIComponent(pass1)
                      xhr.send(userObjXML)
                    
                      xhr.onreadystatechange = function() { // Call a function when the state changes.
                      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                     
                      var ServerResponse = JSON.parse(xhr.responseText)
                   if (ServerResponse.err){
                     
                         alert("Err: 409 Conflict: A user with this email already exists! Please Log In instead.")
      
                    }
                    if (ServerResponse.msg){alert(ServerResponse.msg)
                      document.getElementById('modal-toggle').checked = false
                     window.location = '/'
                  
                  }
                 //   localStorage.setItem("token", key);
                   
                      
                 
                      console.log(ServerResponse)
                    
      }
      }
      
      } else {alert("Passwords don't match!")}
      
      }
          


    return (

      <div id="login_obj" className="notranslate">
        <SEO
        title={'Log In or Sign Up'}
        description={"Log into your Duovocal Account"}
        lang={'en'}
        keywords={"learn,language,german,app,free,flashcards,translate,click,study,spaced-repetition,mobile,ios,android,learn a language"}
      />
        <input id="modal-toggle" type="checkbox" defaultChecked="true" />
        <label className="modal-backdrop" style={{ backgroundImage: `url(${Backdrop}),linear-gradient(${theme.colors.primary} 12.5vh,${theme.colors.secondary})` }} />
        <div className="modal-content" id="modal-outer">
          <div className="tabs">
            {/*  LOG IN  */}
            <input className="radio" id="tab-1" name="tabs-name" type="radio" defaultChecked />
            <label htmlFor="tab-1" className="table"><span>Log In</span></label>
            <div className="tabs-content">
            <StaticImage src="../images/duologo.png"  alt="logo"  placeholder={'blurred'}  style={{width: '90%', maxWidth: '300px', marginTop: '1rem', marginBottom: '1rem'}}  fit={'contain'} width={400} />

           
              <span className="logmod__heading-subtitle">Enter your <strong>login details</strong></span>
              <form action="" onSubmit={()=>{return false}}>
                <input id="enter_email_ele" type="email" placeholder="Email" required />
                <input id="enter_pass_ele" type="password" placeholder="Password" required />
                <input className="reset" value="Log In" type="button" id="login_btn_elem" defaultValue="Log In" onClick={()=>login_submit_function()} />
              </form>
              <form className="forgot-password" action="" onSubmit={()=>{return false}}>
                <input id="forgot-password-toggle" type="checkbox" />
                <label htmlFor="forgot-password-toggle">Forgotten your password?</label>
                <br></br>
                <div className="forgot-password-content">
                  <input id="frgt_pass_email" type="email" placeholder="enter your email" required />
                  <input className="reset"value="Send" type="button" defaultValue="go" id="frgt_pass" onClick={()=>frgt_pass_function()} />
                </div>
              </form>
              <span className="simform__actions-sidetext" style={{fontSize: '12px', color: 'rgba(255, 0, 0, 0.63)', marginBottom: '1.5rem'}}>By creating an account you agree to our <br></br><a className="special" href="/terms-and-conditions/">Terms &amp; Conditions</a><br></br>and confirm that you have read our <br></br> <a className="special" href="/privacy-policy/">Privacy Policy</a></span>
            </div>
            {/*  SIGN UP  */}
            <input className="radio" id="tab-2" name="tabs-name" type="radio" />
            <label htmlFor="tab-2" className="table"><span>Sign Up</span></label>
            <div className="tabs-content">
            <StaticImage src="../images/duologo.png"  alt="logo"  placeholder={'blurred'}  style={{width: '90%', maxWidth: '300px', marginTop: '1rem', marginBottom: '1rem'}}  fit={'contain'} width={400} />

              <span className="logmod__heading-subtitle">Enter your details <strong>to create an acount</strong></span>
              <form action="" onSubmit={()=>{return false}}>
                <input id="signin_email_ele" type="email" placeholder="Email" required />
                <input id="signin_pass1_ele" type="password" placeholder="Password" required />
                <input id="signin_pass2_ele" type="password" placeholder="Confirm password" required />
                <input className="reset" type="button" value="Sign Up" id="signup_btn_elem" defaultValue="Sign Up" onClick={()=>signup_submit_function()} />
                <span className="simform__actions-sidetext" style={{fontSize: '12px', color: 'rgba(255, 0, 0, 0.63)', marginBottom: '1.5rem'}}>By creating an account you agree to our <br></br><a className="special" href="/terms-and-conditions/">Terms &amp; Conditions</a><br></br>and confirm that you have read our <br></br> <a className="special" href="/privacy-policy">Privacy Policy</a></span>
              </form>
            </div>
          </div>
          <div className="logmod__alter">
            <div className="logmod__alter-container">
              <a href="/facebook/" className="connect facebook">
              <StaticImage src="../images/66333845_435505307302447_3399522084286627840_n.png"  alt="facebook logo"  placeholder={'blurred'} style={{maxHeight: '87%', maxWidth: '85vw'}} fit={'contain'} width={380} />

              </a>
              <a href="/auth/google" className="connect googleplus">
              <StaticImage src="../images/btn_google_signin_light_normal_web@2x.png"  alt="Google Logo"  placeholder={'blurred'}  style={{maxHeight: '100%'}} fit={'contain'} width={400} />

          
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default Login